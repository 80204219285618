.toast {
    position: fixed;
    top: 75px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(45, 42, 42);
    color: white;
    text-align: center; 
    padding-top: 8px;
    padding-bottom: 8px;
    display: none;
    z-index: 1000;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    max-width: 90%;
    border-radius: 8px;
    width: 80%;
    align-items: right;
}

.toast.show {
    display: block;
}
