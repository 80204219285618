.product-description-container {
    max-width: 700px;
    margin: 0 auto;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    color: black;
    text-align: left;
  }
  
  .product-description-hzline {
    border: 0;
    height: 1px;
    background: #ccc;
    margin: 20px 0;
  }
  
  .product-description-title {
    font-size: 1.2em;
    font-weight: 400;
  }
  
  .product-description-text {
    font-size: 1em;
    line-height: 1.5;
    padding-left: 10px;
  }
  
  .product-description-specification-container {
    margin-top: 30px;
  }
  
  .product-description-specification-container ul {
    padding: 0;
    margin: 0;
    padding-left: 25px;
  }
  
  .product-description-specification-container ul,
  .product-description-specification-container li {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .product-description-specification-title {
    font-weight: 600;
    text-align: left;
  }

  .product-size-chart-table {
    width: 100%;
    margin-top: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .product-size-chart-table th,
  .product-size-chart-table td {
    border: 1px solid white;
    padding: 10px;
    text-align: left;
  }
  
  .product-size-chart-table th {
    font-weight: 600;
    font-size: 0.8em;
    background-color: #f5f5f5;
  }
  
  .product-size-chart-table td {
    font-size: 0.8em;
    line-height: 1.5;
    background-color: #f9f9f9;
  }

  .product-size-chart-table-cell {
    width: 60px;
  }

  .product-manager-pricing-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    color: black;
    text-align: left;
  }
  
  .product-manager-pricing-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .product-manager-pricing-column {
    width: 48%; /* Adjust as needed, leaving some space for margins */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    margin-left: 10px;
  }

  .product-manager-center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .product-manager-pricing-container label {
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .product-manager-pricing-container input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .product-is-archived {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
  }
  
  .product-is-archived input{
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 30px;
    width: 30px;
  }
  .product-is-archived label {
    margin-bottom: 10px;
    margin-left: 15px;
  }

.admin-product-image-container {
    position: relative;
    display: inline-block;
    margin: 5px;
}

.admin-product-image-container-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    pointer-events: none; /* Ensures that buttons do not interfere with image click */
}

.admin-product-image-container-arrow-button, .admin-product-image-container-delete-button {
    pointer-events: auto;
    background: transparent;
    border: none;
    color: white;
    font-size: 25px;
    padding: 5px;
    position: absolute;
    top: 5px;
    cursor: pointer;
    z-index: 1; 
    margin-left: 5px;
}

.admin-product-image-container-left-arrow {
    left: 5px;
}
.admin-product-image-container-right-arrow {
  margin-left: 25px;
}

.admin-product-image-container-delete-button {
    right: 5px;
}

.product-tag-list-container {
  display: flex;
  flex-wrap: wrap;
}

.product-tag-item {
  background-color: #f2f2f2; /* Light gray background */
  border: 1px solid #555; /* Dark gray border */
  border-radius: 20px; /* Rounded corners */
  padding: 5px 10px; /* Adjust padding as needed */
  margin: 5px; /* Margin between tag items */
  display: flex;
  align-items: center;
}

.product-tag-name {
  margin-right: 5px; /* Margin between tag name and delete button */
}

.product-tag-delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #555; /* Dark gray color */
  font-size: 12px;
}

.product-hash-tag {
  font-size: medium;
  font-weight: 800;
  padding-right: 3px;
}

/* Homepage container */
.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: auto; 
}

.search-bar {
  width: 95%; 
  text-decoration: none;
}

.search-button {
  margin-top: 10px;
  background-color: #e6e5e5;
  border: none;
  padding: 13px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%; 
  
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-weight: 500;
  margin-bottom: 10px;
}

.search-button i {
  margin-right: 10px;
  margin-left: 10px;
  font-weight: 900;
}

.banner {
  margin: 10, auto;
  height: 250px;
  max-width: 700px;
}

.categories-container1 {
  display: flex;
  justify-content: space-around;
  width: 90%;
  max-width: 550px;
  transform: translate(0%, -50%);
}

.categories-container2 {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  width: 90%;
  max-width: 550px;
  transform: translate(0%, -50%);
}

.category-card {
  height: 80px;
  width: 80px;
  background-color: transparent;
  color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.category-card-offer {
  background-color: rgb(119, 0, 255); 
}
.category-card-offer p {
  margin-top: 7px;
  font-size: 7px;
  font-weight: 900;
}
.category-card-offer img {
  width: 40px;
  height: 40px;
  margin-top: 20px;
}


/* Category product list */
.category-product-list {
  margin-top: -20px;
  width: 90%; 
  max-width:500px;
}

.category-description {
  text-align: center;
}

.category-title {
  margin-bottom: 10px;
  font-size:large;
}


.category-product-page-title {
  margin: 0;
  font-size:medium;
}

.category-cover {
  width: 100%;
  border-radius: 10px;
}

.product-card-container {
  transform: translate(0%, -5%);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 500px;
  margin: auto
}

.product-two-card {
  width: calc(50% - 20px); 
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); 
  position: relative; 
  font-size:medium;
}

.product-two-image {
  width: 100%;
  height: 150px; 
  object-fit: cover;
  position: relative; 
}

.product-three-card {
  width: calc(33% - 10px);
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); 
  position: relative; 
  font-size: small;
}

.product-three-image {
  width: 100%;
  height: 100px; 
  object-fit: cover;
  position: relative; 
}

.discount-percentage {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: black;
  color: white;
  padding: 4px;
  border-radius: 5px;
  font-weight: 900;
  z-index: 1; 
}

.discount-font-two {
  font-size: x-small;
}

.discount-font-three {
  font-size: xx-small;
}

.product-name {
  text-align: center;
  padding:0;
  margin-top: 10px;
  margin-bottom: 5px;

  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  min-height: 35px;
}

.product-prices {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.discount-price {
  color: green;
  margin-right: 5px;
  font-weight: 900;
}

.saving-price {
  color: orange;
  margin-left: 5px;
  font-weight: 400;
}

.real-price {
  text-decoration: line-through;
  opacity: 0.5;
}

.no-product-found {
  margin: 0, auto;
  opacity: 0.7;
  font-size: 1.5em;
  margin-top: 300px;
}

.search-input-bar {
  position: relative;
  margin: 0 auto;
  width: calc(100% - 50px); 
  margin-right: 35px;
}

.search-input-icon {
  position: absolute; /* Position the icon absolutely */
  left: 10px; /* Adjust the left position as needed */
  top: 50%; /* Position the icon vertically centered */
  transform: translateY(-50%);
  font-size: 16px;
  color: #333;
  margin-left: 10px;
}

.search-input {
  margin-top: 10px;
  background-color: #e6e5e5;
  border: none;
  padding: 13px;
  border-radius: 10px;
  width: calc(100% - 40px);
  font-weight: 500;
  font-size: medium;
  margin-bottom: 10px;
  padding-left: 40px; 
  margin-left: 5px;
}

.search-input:focus {
  outline: none; 
}


.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}


.search-product-card-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  margin: auto
}

.search-product-card {
  width: calc(50% - 20px); 
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); 
  position: relative; 
  font-size:medium;
  min-width: 100px;
}

.search-product-image {
  width: 100%;
  height: 150px; 
  object-fit: cover;
  position: relative; 
}


.category-item-card {
  width: calc(50% - 20px); 
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); 
  position: relative; 
  font-size:medium;
  min-width: 100px;
  height: 120px;
}

.category-item-name {
  position: absolute;
  top: 50%;
  left: 5px;
  color: rgb(49, 48, 48);
  font-weight: 900;
  font-size: large;
  z-index: 1;
  max-width: 80px;
  word-wrap: break-word;
  transform: translateY(-50%);
  font-family: "Roboto", sans-serif;
  margin-left: 5px;
}

.category-item-image {
  width: 100%;
  height: 135px; 
  object-fit: cover;
  position: relative; 
}

.product-image-slider {
  margin: 0;
  padding: 0;
  width: 100%;
}

.ImageSliderDotContainer {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  color: red;
}

.ImageSliderDot {
  margin: 10px;
  font-size: 45px;
  opacity: 0.5;
  cursor: pointer;
}

.ImageSliderSelectedDot {
  margin: 4px;
  font-size: 55px;
  cursor: pointer;
}

.product-body {
  width: 95%;
}

.product-page-title {
  font-size: 20px;
  color: black;
  text-align: center;
  justify-content: center;
  margin-bottom: 3.5px;
}

.product-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 650px;
  margin: auto; 
  margin-bottom: 200px;
}

.product-page-rating-container {
  display: flex;
  justify-content: center;
  color: #ff9900;
}

.product-page-rating {
  font-size: 22px;
}

.product-page-price-container {
  display: flex;
  justify-content: center;
  font-size: 19px;
  font-weight: 600;
  margin: 7px;
}

.product-page-price-container span{
  margin-right: 10px;
}

.product-page-selling-price {
  text-decoration: line-through;
  opacity: 0.5;
}

.product-page-saving {
  color: green;
}
.product-page-discount-percentage {
  color: red;
}

.product-size-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Horizontally center-align items */
  align-items: center; /* Vertically center-align items */
}

.size-name {
  padding: 12px 12px;
  margin: 4px;
  background-color: #e0e0e0;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-weight: 700;
  border-radius: 5px;
  align-items: center;
}

.size-name.selected {
  background-color: #000;
  color: #fff;
}

.size-name.stock-out {
  opacity: 0.5;
  pointer-events: none;
}

.product-fixed-bar-container {
  position: fixed;
  background-color: rgb(119, 0, 255);
  display: flex;
  align-items: center;

  width: 70%;
  margin: 0 auto;

  bottom: 65px;
  border-radius: 10px;
  justify-content: center;

  color: white;
  font-weight: 700;

  font-size: 1.2em;

  max-width: 700px;
}

.product-fixed-bar-container span {
  padding: 10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
}
.wishlisted-product {
  color: #F23635
}
.product-checkout {
  border: 2px solid #ff439b;
}
.divider{
  font-size: 30px;
  font-weight: lighter;
}
.size-not-selected {
  color: red;
}

.cart-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 650px;
  margin: auto; 
  margin-bottom: 200px;
}

.wishlist-product-container {
  display: flex;
  align-items: center;
  width: 95%;
  height: 120px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  position: relative; 
}

.wishlist-product-image {
  width: 120px;
  height: 120px;
  overflow: hidden;
  margin: 0;
  height: 120;
}
.cart-page-title {
  display: flex;
  align-items: center; /* Center items vertically */
  font-size: 1.4em;
  font-weight: 500;
  margin-bottom: 15px;
}

.cart-page-title .heart-icon {
  font-size: 1.4em; /* Larger font size for heart icon */
  color: #F23635;
  margin-right: 10px;
}

.cart-product-container {
  display: flex;
  align-items: center;
  width: 95%;
  height: 140px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  position: relative; 
}

.cart-product-image {
  width: 140px;
  height: 140px;
  overflow: hidden;
  margin: 0;
  height: 140;
}

.cart-product-details {
  flex: 1;
  padding-left: 12px;
  overflow: hidden;
}

.cart-product-name {
  font-size: 18px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
}

.cart-product-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3px;
}

.cart-delete-button {
  position: absolute;
  top: 15px; /* Adjust vertical position */
  right: 15px; /* Adjust horizontal position */
  cursor: pointer;
  font-size: 18px;
}

.cart-product-info {
  flex: 1;
}

.cart-count-container {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.cart-count-button {
  background-color: #e9e9e9;
  color: #666;
  border: none;
  border-radius: 10px;
  padding: 8px;
  font-size: 12px;
  cursor: pointer;
}

.cart-count {
  background-color: black;
  color: white;
  border-radius: 8px;
  padding: 8px;
  font-size: 12px;
  margin: 0 6px;
}

.cart-calculation {
  width: 95%;
  margin-top: 20px;
  font-weight:500;
}

.cart-calculation-row {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0;
  width: 100%;
}

.cart-calculation-name {
  text-align: left;
  color: black;
}

.cart-calculation-price {
  color: black;
}

.cart-calculation-line {
  border: 1px solid #ddd;
  margin: 0.5rem 0;
}

.cart-fixed-bar-container {
  position: fixed;
  background-color: rgb(119, 0, 255);
  display: flex;
  align-items: center;

  width: 70%;
  margin: 0 auto;

  bottom: 65px;
  border-radius: 10px;
  justify-content: center;

  color: white;
  font-weight: 700;

  max-width: 500px;
  padding: 10px;

  font-weight: 600;
  font-size: 1.3em;
  cursor: pointer;

  z-index: 1000;
}

.start-shopping {
  margin-top: 30px;
  padding: 12px;
  border-radius: 10px;
  background-color: rgb(119, 0, 255);
  width: 70%;
  max-width: 500px;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 1.2em;
  cursor: pointer;
  text-decoration: none;
}

.empty-cart-icon {
  font-size: 8em;
  margin-top: 200px;
  color:#F23635;
}

.coupon-coin-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.2em;
}

.coupon-coin-button {
  background-color: rgb(223, 1, 112); 
  color: white; 
  border: none;
  border-radius: 4px;
  padding: 0.50rem 0.85rem;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); 
  transition: all 0.2s ease-in-out;

  width: 140px;
  margin-left: 15px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.coupon-coin-button:hover {
  background-color: rgb(189, 0, 94); 
}

.coupon-input-container input,
.coin-input-container input {
  flex: 1; 
  border: 1px solid black;
  padding: 0.5rem;
  border-radius: 4px; 
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.gamify-purchase-fixed-bar {
  position: fixed;
  background-color: transparent;
  display: flex;
  align-items: center;

  width: 70%;
  margin: 0 auto;

  bottom: 110px;
  border-radius: 10px;
  justify-content: center;

  color: rgb(119, 0, 255);
  font-weight: 550;

  max-width: 500px;
  padding: 10px;

  font-size: 0.8em;
  cursor: pointer;
}

.loading-fixed-bar {
  position: fixed;
  background-color: white;
  display: flex;
  align-items: center;

  margin: 0 auto;

  bottom: 140px;
}

.delivery-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
}

.delivery-form {
  display: flex;
  flex-direction: column;
}

.delivery-form-group {
  margin-bottom: 20px;
}

.delivery-label {
  font-size: 1.1em;
  margin-bottom: 5px;
  font-weight: 600;
}

.delivery-input{
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #e4e3e3;
  border: 0;
  height: 45px;
}

.delivery-textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  height: 100px;
  border-radius: 10px;
  background-color: #e4e3e3;
  border: 0;
  resize: none;
}

.delivery-district-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin-left: 25px;
  border-radius: 10px;
}

.delivery-district-dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.delivery-district-dropdown-item:hover {
  background-color: #f0f0f0;
}

.checkout-title {
  font-size: 1.2em;
  margin-bottom: 12px;
}

.checkout-payment-options {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.checkout-payment-option {
  display: flex;
  align-items: center;
  background: #f0f0f0;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  width: 300px;
}

.checkout-payment-option.selected {
  background: #000;
  color: #fff;
}

.checkout-payment-option .icon {
  margin-right: 8px;
}

.checkout-payment-option input[type="radio"] {
  position: absolute;
  right: 16px;
  pointer-events: none;
}

.checkout-order-summary {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); 
  padding-left: 40px;
  padding-top: 5px;
  padding-bottom: 20px;
}

.checkout-summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.checkout-summary-item.total {
  font-weight: bold;
  border-top: 1px solid #e0e0e0;
  padding-top: 8px;
  margin-top: 8px;
}

.checkout-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;
  background: white;
}

.checkout-icon {
  padding-right: 5px;
}

.checkout-payment-option.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.success-order-confirmation-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  margin-top: 10px;
}

.success-order-status {
  margin-bottom: 20px;
}

.success-order-icon {
  font-size: 100px;
  border-radius: 50%;
  color: white;
  padding-top: 19px;
  padding-bottom: 19px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: seagreen;
}

.success-order-h2 {
  font-size: 28px;
  margin: 10px 0;
  margin-top: 30px;
}

.success-order-p {
  font-size: 16px;
  color: #505050;
}

.success-order-details li {
  font-size: 16px;
  margin: 5px 0;
}

.success-order-id {
  font-family: monospace;
}

.success-order-details {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.success-order-details h3 {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.success-order-details ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.success-order-item {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.success-order-value {
  margin-left: auto;
  text-align: right;
  color:#313131;
}

.success-copy-btn {
  background: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

.success-order-address-value {
  display: block;
  text-align: left;
  white-space: pre-wrap;
  color:#313131;
}


.success-order-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.success-order-actions button {
  width: 70%;
  margin-bottom: 8px;
  padding: 10px 0;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.success-track-order-btn {
  background-color: rgb(119, 0, 255);
  height: 45px;
  color: #fff;
  font-weight: 600;
}

.success-shop-again-btn {
  background-color: rgb(223, 1, 112); 
  height: 45px;
  color: #fff;
  font-weight: 600;
}

.success-shop-again-btn:hover, .success-track-order-btn:hover {
  opacity: 0.8;
}

/* Optional for better spacing at the bottom */
.success-order-actions button:last-child {
  margin-bottom: 0;
}

.order-history-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  padding: 20px;
  margin-bottom: 100px;
}

.order-history-card {
  max-width: 650px;
  width: 100%; /* Make it responsive */
  margin-bottom: 20px;

  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  cursor: pointer;
}

.order-history-header {
  background-color: rgb(119, 0, 255);
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-history-title{
  font-weight: bold;
  font-size: 16px;
}
.order-history-id{
  font-weight: bolder;
  font-size: 14px;
}

.order-history-body {
  padding: 15px;
}

.order-history-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
}

.order-history-row span {
  color: #333;
}

.order-history-row span:last-child {
  font-weight: bold;
}

.order-details-page {
  font-family: 'Arial', sans-serif;

  max-width: 650px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 100px;
}

.order-details-header {
  background-color: rgb(119, 0, 255); 
  color: white;
  padding: 10px;
  text-align: left; /* Changed alignment to left */
  border-radius: 10px;
  padding-left: 15px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.order-details-header h2 {
  font-size: 1.2em; /* Set h2 font size */
  margin: 0; /* Reset margin for better spacing */
}

.order-details-header p {
  font-size: 0.9em; /* Set p font size */
  margin: 5px 0 0;
  font-weight: 600; /* Add top margin for spacing between h2 and p */
}

.order-details-status {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  margin-top: 20px;
}

.order-details-info-block {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  padding: 10px;
  background-color: #f3f3f3;
  border-radius: 8px;
  margin: 5px 0; /* Space out items vertically */
  padding-left: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.order-details-info-block i {
  font-size: 30px;
  margin-right: 10px; 
}

.order-details-info-block > div {
  display: flex;
  flex-direction: column; /* Stack title and value vertically */
}

.order-details-info-title {
  font-size: 1.1em;
  font-weight: bold;
}

.order-details-info-value {
  margin-top: 5px;
  font-size: 1em;
  color: black;
}

.order-details-info-container{
  margin-left: 10px;
}

.order-details-section {
  padding: 10px;
}

.order-details-address {
  display: flex;
  flex-direction: column; /* Stack icon-text pairs vertically */
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.order-details-address > div {
  display: flex;
  align-items: center; /* Align icon and text vertically */
  margin-bottom: 10px; /* Space between rows */
}

.order-details-address i {
  font-size: 15px;;
  margin-right: 10px; /* Space between icon and text */
}

.order-details-address p {
  margin: 0; /* Remove default margin from paragraphs */
}

.order-details-summary-section {
  padding: 10px;
}

.order-details-summary {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
}

.order-details-summary-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.order-details-total-row {
  font-weight: bold;
  font-size: 18px;
}

.order-details-items-section {
  padding: 10px;
}

.order-details-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 10px;
}

.order-details-item-details {
  max-width: 60%;
}

.order-details-item-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-details-item-price,
.order-details-item-subtotal {
  display: block;
  color: grey;
}

.order-details-item-quantity {
  font-weight: bold;
  margin-bottom: 10px;
}

.order-details-reorder-button {
  padding: 5px 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.order-details-review-button {
  position: absolute;
  bottom: 12px; /* Adjust vertical position */
  right: 15px; /* Adjust horizontal position */
  cursor: pointer;
  font-size: 1em;

  padding: 5px 10px; 
  border-radius: 8px;
  color: white;
  font-weight: 600;
}

.ordered-product{
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative; 
}

.ordered-product-container {
  width: 100%;
  height: auto;
  
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 12px;

  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  max-height: 120px;
  transition: max-height 0.5s ease;
}

.ordered-product-container.expanded {
  max-height: 500px;
}

.order-review-section {
  width: 95%;
  padding: 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-review-section textarea {
  width: 100%;
  height: 60px;
  resize: none;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
}

.order-review-star-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.order-review-star-rating i {
  font-size: 20px;
  color: #ccc;
  padding: 0 5px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.order-review-star-rating i.filled {
  color: #ff9900; 
}

.product-review-container {
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 650px;
  margin: 20px 0;
  margin-left: 20px;
}

.product-review-container h1 {
  font-size: 1.3em;
}

.product-review-container h2 {
  font-size: 1.2em;
}

/* Rating Summary */
.product-review-rating-summary {
  display: flex;
  flex-direction: row; /* Updated to horizontal alignment */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 90%;
}

.product-review-rating-number {
  text-align: left;
  margin-right: 20px;
  margin-left: 5px;
}

.product-review-rating-number h2 {
  font-size: 48px;
  margin: 0;
}

.product-review-rating-number p {
  font-size: 14px;
  color: gray;
}

/* Rating Bars */
.product-review-rating-bars {
  width: 100%;
  max-width: 300px;
}

.product-review-rating-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
}

.product-review-rating-bar span {
  font-size: 16px;
  margin-right: 10px;
}

.bar {
  flex: 1;
  background-color: #e0e0e0;
  height: 8px;
  border-radius: 10px; /* Rounded edges */
  overflow: hidden;
  margin-right: 10px;
}

.fill {
  background-color: red; /* Red color as per the sample image */
  height: 100%;
}

.product-review-count {
  width: 20px;
  text-align: right;
  font-size: 14px;
  color: #999;
}

/* Reviews List */
.product-reviews-list {
  width: 100%;
}

/* Review Card */
.product-review-card {
  background-color: white;
  padding: 20px;
  margin-right: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Profile Picture */
.product-review-profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin-right: 10px;
}

/* Review Header */
.product-review-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.product-reviewer-info {
  flex: 1;
}

.product-reviewer-name {
  font-weight: bold;
  font-size: 16px;
}

.product-review-date {
  font-size: 12px;
  color: gray;
  text-align: right;
}

/* Review Body */
.product-review-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-review-rating {
  font-size: 22px;
  color: #ff9900;
  font-weight: 700;
}

.product-review-text {
  font-size: 14px;
  color: #333;
}

.stars {
  color: #ff9900;
}

.product-reviews-show-more {
  text-align: center;
  margin-top: 10px;
}

.product-reviews-show-more-button {
  background-color: rgb(223, 1, 112);
  color: white;
  border: none;
  padding: 7px 14px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  margin-top: -20;
}

.product-reviews-show-more-button:hover {
  background-color: rgb(255, 25, 140);
}

.product-reviews-show-more-down-arrows {
  margin-right: 10px;
}

.stockout-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(128, 128, 128, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.stockout-overlay-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}