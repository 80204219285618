/* Bottom Navbar Styles */
.bottom-navbar {
    background: white;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    
    z-index: 1000;
  }
  
  .bottom-navbar-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    color: black;
    text-decoration: none;
    flex: 1;
    height: 100%; 
    transform: translate(0%, 15%);
  }
  
  /* Add spacing between the buttons */
  .bottom-navbar-button:not(:last-child) {
    margin-right: 20px;
  }
  
  .bottom-navbar-icon {
    margin-right: 0;
    background: none;
    height: 20px;
    width: 20px;
    border: none;
    cursor: pointer;
  }
  
  /* Add this style to align all button texts vertically */
  .bottom-navbar-text {
    display: inline-block;
    margin-top: 5px;
    vertical-align: middle;
    font-size: 0.8rem;
  }
  
  .bottom-navbar-badge {
    top: 0;
    right: 0;;
    background: black;
    color: white;
    border-radius: 50%;
    padding: 2px 4px;
    font-size: 9px;
    font-weight: bold;
    transform: translate(70%, -320%);
  }
  
  .bottom-navbar-button-nobadge {
    padding-bottom: 10px;
  }