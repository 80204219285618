.profile-header {
    background: white;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    
    display: flex;
    justify-content: space-around;
    align-items: center; /* Align items vertically */
  }
  
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: white;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .user-info {
    text-align: left;
  }
  
  .user-info > div {
    margin-bottom: 5px;
  }
  
  .username {
    font-weight: bold;
    font-size: large;
  }
  
  .registered-since {
    font-weight: normal;
    font-size: smaller;
  }
  .registration-icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
  
  .user-points-container {
    background: black;
    border-radius: 20px;
    padding: 5px;
    margin-left: 5px;
  }
  
  .user-points {
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
  }
  
  
  .gold-coin,
  .total-points,
  .points-text {
    margin: 3px; 
  }

  .gold-coin{
    height: 15px;
    width: 15px;
  }

  .total-points{
    font-size: 15px;
    font-weight: bold;
  }
  
  
  .profile-row {
    background: #f2f2f2;
    padding: 10px;
    display: flex;
    align-items: left;
    border-radius: 10px;
    margin: 10px;
    margin-bottom: 15px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .editable {
    border: none;
    background: none;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .order-history {
    background: #d1064a;
    color: white;
    text-decoration: none;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .update-profile,
  .logout {
    width: 100%;
    background: black;
  }

  .btn{
    border: none;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    background: transparent;
    color: white;
  }
  
  @media (max-width: 768px) {
    .profile-image {
      font-size: 1.5rem;
    }
  
    .gold-coin {
      font-size: 1.5rem;
    }
  
    .icon {
      font-size: 1.5rem;
    }
  
    .editable {
      font-size: 0.9rem;
    }
  }

  .image-icon  {
    margin-right: 0;
    background: none;
    height: 25px;
    width: 25px;
    border: none;
    cursor: pointer;
  }
  .order-history-image{
    display: flex;
    flex-direction: column;
    align-items: left;
    flex: 1;
    height: 100%; 
  }
  

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  /* Style the editable input for this specific case */
  .editable-light {
    border: none;
    background: #f0f0f0; /* Light background color */
    text-align: left;
    font-size: 1rem;
    font-weight: normal;
    margin-left: 15px;
    width: 100%;
  }
  .textbox{
    border-bottom: 0.5px solid #afaeae;
  }

  .order-history-text {
    border: none;
    background: #d1064a;
    color: white;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    margin-left: 15px;
    width: 100%;
  }

  .right-icon {
    width: 20px;
    height: 20px;
  }
  
  .order-history:hover {
    cursor: pointer;
  }
  
  .submit-btn:hover {
    cursor: pointer;
  }
  
  
  .submit-btn{
    background: #000;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    text-decoration: none;
  }
  
  .submit-btn-text{
    border: none;
    background: #000;
    color: white;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    margin-left: 15px;
    width: 100%;
  }
  
  .profile-container {
    max-width: 500px;
    margin: 0 auto;
  }

  .formErrors{
    font-size: 10px;
    color: red;
    transform: translate(0%,-2050%);
    text-align: right;
    padding-right: 20px;
    height: 0.5px;
  }