body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #fff; /* Set the background to white */
    color: #000; /* Set text color to black */
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    text-align: center;
    background-color: #fff;
    color: #000;
  }
    
  
  .back-button {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
  }
  
  .back-button img {
    width: 20px;
    height: 20px;
    filter: invert(1); /* Invert the arrow color for white background */
  }
  
  .brand {
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: #000;
  }
  
  .brand img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  
  h1 {
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .bold {
    font-weight: bold;
  }
  
  .light {
    font-weight: lighter;
  }
  
  .tagline {
    font-size: 1rem;
    margin: 10px 0;
    color: #000;
    font-weight: bold;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 10px 0;
  }
  
  .input-container img {
    width: 24px;
    height: 24px;
    padding: 10px;
    background-color: #f4f4f4;
    border-radius: 10px 0 0 10px;
    /* filter: invert(1); Invert the phone icon color for white background */
  }
  
  .input-box {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 0 10px 10px 0;
    background-color: #f4f4f4;
    font-size: 1rem;
    color: #333;
  }
  
  .login-button {
    width: 100%;
    padding: 15px 8px;
    background-color: #000;
    border: none;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .login-button:hover {
    background-color: rgb(28, 30, 28);
  }
  